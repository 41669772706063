import React from "react";
import Box from "@material-ui/core/Box";
import { Trans } from "@lingui/macro";
import Typography from "@material-ui/core/Typography";

const TabLabel = ({ tabName, children }) => {
  return (
    <Box display="flex" justifyContent="flexStart" alignItems="center" width="100%">
      <Box sx={{ display: { xs: "none", md: "block" } }} mr={1}>
        {children}
      </Box>
      <Typography variant="body1">
        <Trans>{tabName}</Trans>
      </Typography>
    </Box>
  );
};

export default TabLabel;
