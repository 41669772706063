import React from "react";
import theme from "../../theme";
import Box from "@material-ui/core/Box";
import { Button, Dialog, makeStyles, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import CloseIcon from "../../icons/close-icon";
import { isMobile } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  titleAlignment: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
  },
  button: {
    width: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
  closeIcon: {
    position: "absolute",
    right: 15,
    cursor: "pointer",
    top: 15,
  },
}));
const DialogCancel = ({ open, onClose, onCancelAndContinue, onSubmitAndContinue }) => {
  const classes = useStyles(theme);
  return (
    <Dialog
      open={open}
      onClose={() => {}}
      fullScreen={isMobile()}
      disableScrollLock
      PaperProps={{
        style: {
          display: "flex",
          justifyContent: "center",
          borderBox: "box-size",
          borderRadius: isMobile() ? "0px" : "10px",
          width: isMobile() ? "100%" : 441,
        },
      }}
    >
      <Box className={classes.card}>
        <Box className={classes.closeIcon} onClick={onClose}>
          <CloseIcon width="15px" height="15px" />
        </Box>
        <Box className={classes.titleAlignment}>
          <Typography variant="h1">
            <Trans>Existen cambios sin guardar</Trans>
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body1">
            <Trans>
              Al editar un campo debes guardar los cambios. ¿Desea guardar los cambios realizados antes de continuar?
            </Trans>
          </Typography>
        </Box>
        <Box>
          <Button
            variant={"text"}
            color={"primary"}
            style={{ marginRight: theme.spacing(1) }}
            onClick={onCancelAndContinue}
          >
            <Trans>Continuar sin guardar</Trans>
          </Button>
          <Button variant={"contained"} color={"primary"} onClick={onSubmitAndContinue}>
            <Trans>Guardar y continuar</Trans>
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default DialogCancel;
