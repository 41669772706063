import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

import theme from "../../theme";

import TextField from "../text-field";
import { ValidatorForm } from "react-material-ui-form-validator";
import CardProfile from "./card-profile";
import FooterBtnProfile from "./footer-btn-profile";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  validatorContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  textContainerBase: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    flex: "1",
    width: "100%",
    marginTop: theme.spacing(2),
    "& label + .MuiInput-formControl": {
      marginTop: theme.spacing(1),
    },
  },
  textContainerColumns: {
    "&:nth-child(odd)": {
      marginRight: theme.spacing(2),
    },
    "&:nth-child(even)": {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      "&:nth-child(odd)": {
        marginRight: 0,
      },
      "&:nth-child(even)": {
        marginLeft: 0,
      },
    },
  },
}));
const ResetPasswordTab = ({ onSubmit, resetPasswordStatus, setHasAnyChange }) => {
  const classes = useStyles(theme);
  const { i18n } = useLingui();
  const [valuesResetPassword, setValuesResetPassword] = useState({
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
  });
  const [isDisabledSave, setIsDisabledSave] = useState(true);

  const formRef = useRef(null);

  const handleInputChange =
    (field) =>
    ({ target }) => {
      setValuesResetPassword((state) => ({ ...state, [field]: target.value }));
    };

  const handleSubmit = () => {
    onSubmit(valuesResetPassword);
  };

  const handleResetForm = () => {
    setValuesResetPassword({
      currentPassword: "",
      password: "",
      passwordConfirmation: "",
    });
  };

  useEffect(() => {
    ValidatorForm.removeValidationRule("isPasswordMatch");
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      const password = formRef.current.childs[1].state.value;
      if (value !== password) {
        return false;
      }
      return true;
    });
    return () => {
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, []);

  useEffect(async () => {
    const isFormValid = await formRef.current.isFormValid();
    setIsDisabledSave(!isFormValid);
    setHasAnyChange({ status: isFormValid, payload: valuesResetPassword, from: "resetPassword" });
  }, [valuesResetPassword]);

  useEffect(() => {
    if (resetPasswordStatus === "success") {
      setValuesResetPassword({
        currentPassword: "",
        password: "",
        passwordConfirmation: "",
      });
    }
  }, [resetPasswordStatus]);

  return (
    <>
      <CardProfile>
        <Container>
          <Box sx={{ display: { xs: "none", md: "block" }, fontWeight: "bold" }}>
            <Typography variant="h4" color="textPrimary">
              <Trans>Inicio de sesión</Trans>
            </Typography>
          </Box>
          <Box mt={3}>
            <ValidatorForm ref={formRef} className={classes.validatorContainer} onSubmit={() => {}}>
              <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }}>
                <TextField
                  type="password"
                  label={`${i18n._(t`Contraseña actual`)}`}
                  value={valuesResetPassword?.currentPassword}
                  InputProps={{
                    placeholder: `${i18n._(t`Contraseña actual`)}`,
                  }}
                  onChange={handleInputChange("currentPassword")}
                  validators={["required"]}
                  errorMessages={[`${i18n._(t`Este campo es obligatorio`)}`]}
                  inputContainerClassNames={classes.textContainerBase}
                  fullWidth
                />
              </Box>
              <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" } }} mt={[0, 2]}>
                <TextField
                  name="password"
                  type="password"
                  label={`${i18n._(t`Contraseña nueva`)}`}
                  value={valuesResetPassword?.password}
                  InputProps={{
                    placeholder: `${i18n._(t`Contraseña nueva`)}`,
                  }}
                  onChange={handleInputChange("password")}
                  validators={["required", "matchRegexp:^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,24}$"]}
                  errorMessages={[
                    `${i18n._(
                      t`La contraseña debe tener entre 8 y 24 caracteres y contener al menos un número, una letra minúscula y una mayúscula.`,
                    )}`,
                  ]}
                  inputContainerClassNames={`${classes.textContainerBase} ${classes.textContainerColumns}`}
                  fullWidth
                />
                <TextField
                  name="passwordConfirmation"
                  type="password"
                  label={`${i18n._(t`Confirmar la nueva contraseña`)}`}
                  value={valuesResetPassword?.passwordConfirmation}
                  InputProps={{
                    placeholder: `${i18n._(t`Confirmar la nueva contraseña`)}`,
                  }}
                  onChange={handleInputChange("passwordConfirmation")}
                  inputContainerClassNames={`${classes.textContainerBase} ${classes.textContainerColumns}`}
                  validators={["isPasswordMatch", "required"]}
                  errorMessages={[
                    `${i18n._(t`Debes confirmar la misma contraseña.`)}`,
                    `${i18n._(t`Este campo es obligatorio`)}`,
                  ]}
                  fullWidth
                />
              </Box>
            </ValidatorForm>
          </Box>
        </Container>
      </CardProfile>
      <FooterBtnProfile
        onCancel={handleResetForm}
        disabled={isDisabledSave}
        onSubmit={handleSubmit}
        status={resetPasswordStatus}
      />
    </>
  );
};
export default ResetPasswordTab;
