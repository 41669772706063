import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Trans } from "@lingui/macro";
import VisibilitySwitchComponent from "../listing-form/visibility-switch";
import CardProfile from "./card-profile";
import FooterBtnProfile from "./footer-btn-profile";

const Notification = () => {
  return (
    <>
      <CardProfile>
        <Container>
          <Box sx={{ display: { xs: "none", md: "block" }, fontWeight: "bold" }}>
            <Typography variant="h4" color="textPrimary">
              <Trans>Notificaciones</Trans>
            </Typography>
          </Box>
          <Box mt={3}>
            <VisibilitySwitchComponent
              isDisabled={true}
              label={
                <Typography variant="body1" color="textPrimary" style={{ fontWeight: "bold" }}>
                  <Trans>Recibir notificaciones</Trans>
                </Typography>
              }
              searchable={true}
              onClickPublish={() => {}}
            />
          </Box>
        </Container>
      </CardProfile>
      <FooterBtnProfile onSubmit={() => {}} />
    </>
  );
};
export default Notification;
