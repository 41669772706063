import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
// width={width} height={height}
const PencilIcon = ({ fill = "#333333", width = 12, height = 12, ...rest }) => (
  <SvgIcon width="12px" height="12px" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M2.16454 9.79114C2.18158 9.79114 2.21567 9.79114 2.23271 9.79114L4.8914 9.21168C5.11296 9.1776 5.33451 9.07534 5.50494 8.88787L11.2825 3.11033C11.7256 2.66721 11.7256 1.93437 11.2825 1.49125L10.1236 0.332336C9.68045 -0.110779 8.94761 -0.110779 8.50449 0.332336L2.72695 6.12692C2.55652 6.29735 2.45427 6.51891 2.40314 6.74046L1.82368 9.39915C1.80664 9.50141 1.84072 9.62071 1.90889 9.70593C1.99411 9.75705 2.07932 9.79114 2.16454 9.79114ZM2.84625 7.84825L3.78361 8.78561L2.59061 9.04125L2.84625 7.84825ZM8.98169 0.809537C9.06691 0.724323 9.20325 0.673194 9.32255 0.673194C9.45889 0.673194 9.57819 0.724323 9.66341 0.809537L10.8223 1.96845C10.9075 2.05367 10.9587 2.19001 10.9587 2.30931C10.9587 2.44565 10.9075 2.56496 10.8223 2.65017L10.3451 3.12737L8.50449 1.2697L8.98169 0.809537ZM8.04433 1.72985L9.88496 3.57049L5.04478 8.42771C4.85731 8.61518 4.5335 8.61518 4.34603 8.42771L3.18711 7.26879C3.1019 7.18358 3.05077 7.04723 3.05077 6.92793C3.05077 6.79159 3.1019 6.67229 3.18711 6.58708L8.04433 1.72985Z"
      fill={fill}
    />
    <path
      d="M11.4699 10.8824H0.323815C0.136343 10.8824 0 11.0358 0 11.2062C0 11.3937 0.153386 11.53 0.323815 11.53H11.4869C11.6744 11.53 11.8107 11.3766 11.8107 11.2062C11.7937 11.0187 11.6573 10.8824 11.4699 10.8824Z"
      fill={fill}
    />
  </SvgIcon>
);

export default PencilIcon;
