import React from "react";
import Box from "@material-ui/core/Box";
import theme from "../../theme";

const CardProfile = ({ children }) => {
  return (
    <Box
      sx={{
        px: { xs: 0, md: 4 },
        pt: 3,
        pb: { sm: 9, md: 7 },
        ml: 3,
        mr: 3,
        border: { sm: 0, md: theme.borders[6] },
        height: "calc(100vh - 180px)",
      }}
      borderRadius={5}
    >
      {children}
    </Box>
  );
};
export default CardProfile;
