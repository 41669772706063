import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PadlockIcon = ({ fill = "#333333", width = 16, height = 16, ...rest }) => (
  <SvgIcon width={width} height={height} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0106 10.5789C13.0106 10.0737 13.0106 9.55789 13.0106 9.05262C13.0106 8.36841 12.6737 7.85262 12.0843 7.63157C11.9685 7.58946 11.8527 7.56841 11.7264 7.53683C11.6632 7.52631 11.6001 7.51578 11.5369 7.49473L11.4948 7.4842V6.75789C11.4948 6.36841 11.4948 5.95789 11.4948 5.56841C11.4948 5.30525 11.4843 5.01052 11.4316 4.72631C11.0737 2.54736 9.22111 0.957886 7.01058 0.957886C6.70532 0.957886 6.38953 0.989465 6.08427 1.05262C4.08427 1.46315 2.62111 3.15789 2.51585 5.17894C2.49479 5.65262 2.49479 6.13683 2.49479 6.59999C2.49479 6.82104 2.49479 7.03157 2.49479 7.25262V7.49473L2.45269 7.50525C2.40006 7.51578 2.35795 7.52631 2.29479 7.52631C2.20006 7.53683 2.11585 7.55789 2.03164 7.57894C1.379 7.75789 0.979004 8.30525 0.979004 9.0421C0.979004 9.93683 0.979004 10.8316 0.979004 11.7263V12.8631C0.979004 13.1263 0.979004 13.6947 0.979004 13.6947C0.979004 14.2947 0.979004 14.9053 0.979004 15.5158C0.98953 16.4 1.58953 17.0105 2.44216 17.0105C3.71585 17.0105 4.98953 17.0105 6.26321 17.0105C8.15795 17.0105 9.88427 17.0105 11.5264 17C11.7369 17 11.9685 16.9474 12.1685 16.8631C12.7158 16.6105 12.9895 16.1474 13.0001 15.4737C13.0001 14.4842 13.0001 13.5053 13.0001 12.5158V11.2947L13.0106 10.5789ZM3.42111 5.4842C3.44216 3.72631 4.54743 2.33683 6.24216 1.95789C6.49479 1.90525 6.75795 1.87368 7.02111 1.87368C8.91585 1.87368 10.5158 3.41052 10.579 5.29473C10.6001 5.78946 10.5895 6.29473 10.5895 6.77894C10.5895 7.01052 10.5895 7.2421 10.5895 7.47368V7.52631H3.43164L3.42111 7.43157C3.42111 7.38946 3.41058 7.35789 3.41058 7.32631V6.82104C3.41058 6.41052 3.41058 5.93683 3.42111 5.4842ZM11.4316 16.1263H2.56848C2.08427 16.1263 1.89479 15.9368 1.89479 15.4526C1.89479 13.3368 1.89479 11.2316 1.89479 9.11578C1.89479 8.62104 2.07374 8.4421 2.56848 8.4421H11.4316C11.9053 8.4421 12.0948 8.63157 12.0948 9.10525C12.0948 11.221 12.0948 13.3368 12.0948 15.4526C12.1053 15.9368 11.9158 16.1263 11.4316 16.1263Z"
      fill={fill}
    />
    <path
      d="M7.179 10.0736C7.11585 10.0631 7.04216 10.0631 6.979 10.0631C6.40006 10.0631 5.84216 10.4421 5.64216 10.9789C5.379 11.6526 5.60006 12.3894 6.15795 12.6947C6.49479 12.8736 6.60006 13.1052 6.54742 13.4842C6.54742 13.5263 6.54742 13.8631 6.54742 14.0315C6.54742 14.3368 6.71585 14.5052 6.98953 14.5052H7.00006C7.27374 14.5052 7.44216 14.3263 7.44216 14.021C7.44216 13.9052 7.44216 13.7894 7.44216 13.6526C7.44216 13.4631 7.44216 13.2631 7.44216 13.0631C7.44216 12.9894 7.49479 12.8947 7.56848 12.8631C8.20006 12.5578 8.52637 11.9894 8.44216 11.3368C8.35795 10.6421 7.88427 10.1789 7.179 10.0736ZM7.00006 12.0736C6.84216 12.0736 6.69479 12.021 6.60006 11.9157C6.50532 11.8105 6.45269 11.6736 6.45269 11.5157C6.45269 11.2 6.65269 10.9894 6.95795 10.9684V10.9157H7.06321V10.9789C7.36848 11 7.56848 11.221 7.56848 11.5368C7.55795 11.8526 7.32637 12.0736 7.00006 12.0736Z"
      fill={fill}
    />
  </SvgIcon>
);

export default PadlockIcon;
