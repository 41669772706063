import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../api";
import ProfileComponent from "../../components/profile";
import { getUserThunk, updateAvatarUser } from "../../redux/actions/user-actions";

const Profile = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.user.data.isAdmin);
  const loggedUser = useSelector((state) => state.user.data);
  const [userPatchStatus, setUserPatchStatus] = useState("initial");
  const [resetPasswordStatus, setResetPasswordStatus] = useState("initial");
  const [fileUploadStatus, setFileUploadStatus] = useState({ status: "initial" });

  const handleSubmitProfile = async (values) => {
    setUserPatchStatus("loading");
    const res = await api.patchUser(values.userId, values.payload);
    if (res.status === 200) {
      setUserPatchStatus("success");
    } else {
      setUserPatchStatus("error");
    }
    dispatch(getUserThunk({ isAdmin }));
    setTimeout(() => setUserPatchStatus("initial"), 3000);
  };

  const handleSubmitResetPassword = async (resetPassword) => {
    setResetPasswordStatus("loading");
    const res = await api.changePassword(resetPassword);
    if (res.status === 200) {
      setResetPasswordStatus("success");
    } else {
      setResetPasswordStatus("error");
    }
    setTimeout(() => setResetPasswordStatus("initial"), 3000);
  };

  const handleOnUpdateFile = async (statusUpdated) => {
    if (statusUpdated.status === 200) {
      dispatch(updateAvatarUser(statusUpdated.data.payload.avatar));
      setFileUploadStatus({ status: "initial" });
    } else {
      setFileUploadStatus({ status: "error", error: statusUpdated.data?.data?.message });
      setTimeout(() => setFileUploadStatus("initial"), 3000);
    }
  };

  const handleOnStartUpdateFile = () => setFileUploadStatus({ status: "loading" });

  const handleOnErrorUpdateFile = () => setFileUploadStatus({ status: "error" });

  return (
    <ProfileComponent
      user={loggedUser}
      onSubmitProfile={handleSubmitProfile}
      userPatchStatus={userPatchStatus}
      onSubmitResetPassword={handleSubmitResetPassword}
      resetPasswordStatus={resetPasswordStatus}
      onStartUpdateFile={handleOnStartUpdateFile}
      onUpdateFile={handleOnUpdateFile}
      onErrorUpdateFile={handleOnErrorUpdateFile}
      fileUploadStatus={fileUploadStatus}
    />
  );
};

export default Profile;
