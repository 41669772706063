import React from "react";
import theme from "../../theme";
import Box from "@material-ui/core/Box";
import { Button, Typography } from "@material-ui/core";
import { Trans } from "@lingui/macro";

const FooterBtnProfile = ({ onSubmit, onCancel, disabled = true, status = "" }) => {
  return (
    <Box
      sx={{
        height: "59px",
        display: "flex",
        position: { xs: "fixed", md: "absolute" },
        justifyContent: "space-between",
        bottom: { xs: 0, md: 8 },
        left: 0,
        width: "100%",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        alignItems: "center",
        boxShadow: { xs: "0px -4px 11px rgba(0, 0, 0, 0.25)", md: "none" },
        backgroundColor: theme.palette.white,
      }}
    >
      <Box>
        {status === "success" && (
          <Typography variant="body1" style={{ color: theme.palette.successGreen }}>
            <Trans>¡Los cambios han sido guardados existosamente!</Trans>
          </Typography>
        )}
        {status === "error" && (
          <Typography variant="body1" style={{ color: theme.palette.error.main }}>
            <Trans>Ups hubo un error intente nuevamente más tarde!</Trans>
          </Typography>
        )}
      </Box>
      <Box>
        <Button
          variant={"text"}
          color={"primary"}
          style={{ marginRight: theme.spacing(1) }}
          onClick={onCancel}
          disabled={disabled || status === "loading"}
        >
          <Trans>Cancelar</Trans>
        </Button>
        <Button variant={"contained"} color={"primary"} onClick={onSubmit} disabled={disabled || status === "loading"}>
          <Trans>Guardar</Trans>
        </Button>
      </Box>
    </Box>
  );
};
export default FooterBtnProfile;
