import React from "react";

export default function CloseModalIcon({
  fill = "black",
  width = 16,
  height = 16,
  ...rest
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.00005 15.2008C4.02952 15.2008 0.799316 11.9706 0.799316 8.00005C0.799316 4.02952 4.02952 0.799316 8.00005 0.799316C11.9706 0.799316 15.2008 4.02952 15.2008 8.00005C15.2008 11.9706 11.9706 15.2008 8.00005 15.2008ZM8.00005 1.26598C4.28692 1.26598 1.26598 4.28692 1.26598 8.00005C1.26598 11.7132 4.28692 14.7341 8.00005 14.7341C11.7132 14.7341 14.7341 11.7132 14.7341 8.00005C14.7341 4.28692 11.7132 1.26598 8.00005 1.26598Z"
        fill="#333333"
      />
      <path
        d="M11.787 4.54282L11.457 4.21289L4.21269 11.4572L4.54262 11.7872L11.787 4.54282Z"
        fill="#333333"
      />
      <path
        d="M11.7873 11.4572L4.54297 4.21289L4.21304 4.54282L11.4574 11.7872L11.7873 11.4572Z"
        fill="#333333"
      />
    </svg>
  );
}
